<template>
  <div @click.stop class="container-models-select d-none" ref="container_models">
    <div class="d-flex justify-content-between">
      <h5>{{name_model.title}}</h5>
      <feather-icon icon="XIcon" @click="toggleContainer()" class="cursor-pointer"></feather-icon>
    </div>

    <div class="grid-models">
      <div v-for="(model, index) in models_imagine" :key="index" class="position-relative" @click="$emit('select_model', model, name_model.model); toggleContainer();">
        <b-img v-if="model.category" :src="getUrlCategory(model.category)" class="w-100"></b-img>
        <div v-else class="no-style-container">
          <b-img :src="require('@/assets/images/svg/deny.svg')" class="w-50 mb-1"></b-img>
        </div>
        <span class="text-title-model" :class="{'text-dark': !model.category}">{{ model.name }}</span>
      </div>
    </div>
  </div> 
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue';

import { getUrlCategory } from '@/libs/utils/urls';

export default {
  name: 'dropdownModels',
  components: {
    BImg,
  },
  props: {
    models_imagine: {
      type: Array
    },
    name_model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      getUrlCategory,
    }
  },
  mounted() {
    document.addEventListener('click', this.removeContainerEv);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.removeContainer);
  },
  methods: {
    removeContainerEv(event) {
      if (this.$refs['container_models'] && !this.$refs['container_models'].contains(event.target)) {
        this.$refs['container_models'].classList.add('d-none')
      }
    },
    toggleContainer() {
      this.$refs['container_models'].classList.toggle('d-none');
    },

  }
}
</script>
<style scoped lang="scss">
.container-models-select {
  background-color: white;
  width: 370px;
  position: absolute;
  left: -25.5em;
  border: 1px solid #d8d6de5d;
  border-radius: 0.35rem;
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  z-index: 100;
  @media(max-width: 1199px) {
    left: 0.5em;
  }
} 
.grid-models {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.3em;
  cursor: pointer;
  img {
    border-radius: 0.5em;
    filter: brightness(80%);
  }
  .no-style-container {
    border-radius: 0.5em;
    border: 1px solid #4285F4;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-title-model {
    color: white;
    position: absolute;
    bottom: 0.5em;
    left: 0;
    z-index: 100;
    width: 100%;
    text-align: center;
  }
}
</style>